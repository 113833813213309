.menu-row {
    width: 100%;
    margin: 0;
    max-width: 391.5px;
}

.col-style {
    padding: 10px;
    top: 0;
    background-color: #eef3f9;
}

.title-col {
    position: sticky;
    z-index: 1;
}

/* .content-col {
    padding: 10px;
    top: 0;
    background-color: #eef3f9;
} */

.menu-hr {
    width: 95%;
    border-top: 3px solid #999;
}

.accordion-menu {
    transform: rotate(-90deg) !important;
    display: inline-block;
    font-weight: bold;
}

.font-blue {
    color: blue !important;
}

.font-size-085 {
    font-size: 0.85em !important;
}

.colon {
    width: 2% !important;
    margin-left: 0.27em !important;
    margin-right: 0.27em !important;
    padding-top: 0.5em !important;
}

.from-to {
    padding-top: 0.5em !important;
    text-align: center;
    display: inline-block;
}

.call-time {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0.5em !important;
}

.call-time .padding-right-0 .form-group .col-sm-12 {
    padding-left: 0;
    padding-right: 0;
}

.call-time .col-lg-2 {
    width: 18.2% !important;
}

.width-40 {
    width: 40.9% !important;
}

.talk .call-time {
    width: 105% !important;
}

.keyword {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.condition .checkbox {
    margin-top: 0 !important;
}

.summary-period .form-group .col-sm-12 {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

.button-size {
    width: 9em !important;
}

.clear-button {
    margin-right: 0.5em !important;
    background-color: rgb(216, 216, 216);
    color: gray;
}

.margin-left-10 {
    margin-left: 1em;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.margin-top-25 {
    margin-top: 2.5em !important
}

.margin-top-27 {
    margin-top: 2.7em !important
}

.padding-bottom-35 {
    padding-bottom: 3.5em !important;
}

.padding-bottom-08 {
    padding-bottom: 0.8em !important;
}